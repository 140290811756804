<template>
  <div>
    <b-card title="Transacciones de jugadas">
      <!-- FILTER START -->
      <div class="mb-1">
        <b-row>
          <b-col md="3">
            <b-form-group rules="required">
              <label class="mr-1">{{ $t("labels.datefrom") }}</label>

              <flat-pickr
                v-model="filterSelect.dateFrom"
                class="form-control"
                :config="{
                  altInput: true,
                  altFormat: 'd-m-Y H:i',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group rules="required">
              <label class="mr-1">{{ $t("labels.dateto") }}</label>
              <flat-pickr
                v-model="filterSelect.dateTo"
                class="form-control"
                :config="{
                  altInput: true,
                  altFormat: 'd-m-Y H:i',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
              <!-- <label class="mr-1 text-danger" v-if="filterSelect.dateFrom">Rango maximo de 30 dias </label> -->
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group>
              <label class="mr-1 text-lg" for="customRadioInline1"
                >Clientes
                <span class="text-danger">*</span>
              </label>
              <v-select
                v-model="filterSelect.selectCustomer"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerOptions"
                label="name"
                @option:selected="selectCustomer"
                :reduce="(customer) => customer._id"
                placeholder="Seleccione un cliente"
                value=""
              >
                <template #option="{ name }">
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label class="mr-1" for="customRadioInline1">WL </label>
              <v-select
                v-model="filterSelect.WL"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="wlOptions"
                label="_id"
                placeholder="Select WL"
                value=""
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label class="mr-1" for="customRadioInline1"
                >Productos
                <span class="text-danger">*</span>
              </label>
              <v-select
                v-model="filterSelect.selectProduct"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productsOptions"
                label="name"
                :reduce="
                  (product) => ({
                    productId: product.productId,
                    _id: product._id,
                  })
                "
                placeholder="Select product"
                value=""
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label class="mr-1">Monedas</label>
              <v-select
                v-model="filterSelect.selectCurrency"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="currencyssOptions"
                placeholder="Seleccione la moneda"
                value="Bs"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label class="mr-1 text-lg" for="customRadioInline1"
                >Juegos
              </label>
              <v-select
                v-model="filterSelect.selectGames"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="gamesOptions"
                label="gameName"
                :reduce="(game) => game.gameId"
                multiple
                :disabled="!filterSelect.selectProduct"
                @search="getGames"
                :filterable="false"
                :loading="loadingGames"
                :close-on-select="false"
                placeholder="Seleccione o busque juegos"
              >
                <template #option="{ gameName }">
                  <feather-icon
                    icon="CpuIcon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ gameName }}</span>
                </template>

                <template #list-header>
                  <div class="d-flex justify-content-center my-1">
                    <b-button
                      size="sm"
                      @click="selectAllGames"
                      class="text-nowrap"
                      variant="primary"
                      v-if="gamesOptions.length > 1 && !areAllGamesSelected"
                    >
                      <feather-icon icon="CheckSquareIcon" class="mr-50" />
                      <span class="text-nowrap">Seleccionar Todo</span>
                    </b-button>
                    <b-button
                      size="sm"
                      @click="unselectAllGames"
                      class="text-nowrap"
                      variant="primary"
                      v-if="filterSelect.selectGames.length > 0"
                    >
                      <feather-icon icon="SquareIcon" class="mr-50" />
                      <span class="text-nowrap">Deseleccionar Todo</span>
                    </b-button>
                  </div>
                </template>

                <template #no-options> Escriba para buscar juegos... </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label class="mr-1">Buscador</label>
              <b-form-input
                v-model="filterSelect.q"
                class="d-inline-block mr-1"
                :placeholder="$t('labels.search')"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col class="d-flex align-items-center justify-content-end">
            <b-button
              :disabled="loadingData"
              @click="inputSelect()"
              class="text-nowrap"
              variant="primary"
            >
              <b-spinner small v-if="loadingData"></b-spinner>
              <feather-icon
                v-if="!loadingData"
                icon="SearchIcon"
                class="mr-50"
              />
              <span v-if="!loadingData" class="text-nowrap">{{
                $t("labels.search")
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- FILTER END -->

    <b-modal
      id="modalResponse"
      title="PopUp Response"
      cancel-variant="outline-secondary"
      scrollable
    >
      <b-card-text>
        {{ rows }}
      </b-card-text>
    </b-modal>

    <b-card>
      <b-overlay :show="loadingData" variant="transparent">
        <b-row class="mb-1">
          <b-col md="6"></b-col>
          <b-col md="6" class="d-flex align-items-center justify-content-end">
            <b-button
              @click="downloadFile()"
              class="text-nowrap"
              :variant="rows.length > 0 ? 'primary' : 'secondary'"
              :disabled="rows.length > 0 ? false : true"
            >
              <b-spinner small v-if="exportDataLoading"></b-spinner>
              <feather-icon
                v-if="!exportDataLoading"
                :icon="rows.length > 0 ? 'DownloadIcon' : 'XIcon'"
                class="mr-50"
              />
              <span v-if="!exportDataLoading" class="text-nowrap">{{
                $t("labels.export")
              }}</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :totalRows="totalRows"
          :pagination-options="{ enabled: true }"
          :search-options="{
            enabled: true,
            externalQuery: searchQuery,
          }"
          :sort-options="{ enabled: false }"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'createdAt'">
              <div class="text-nowrap">
                {{ props.row.createdAt | formatDate }}
              </div>
            </div>
            <div v-else-if="props.column.field == 'type'">
              <!-- debit or credit span danger success -->
              <span
                :class="
                  props.row.type == 'debit' ? 'badge-danger' : 'badge-success'
                "
                class="badge"
                >{{ props.row.type == "debit" ? "Debit" : "Credit" }}</span
              >
            </div>
            <div v-else-if="props.column.field == 'amount'">
              <div>{{ props.row.amount | currency({ symbol: "" }) }}</div>
            </div>

            <div v-else-if="props.column.field == 'user.username'">
              <div v-if="props.row.user.length > 0">
                {{ props.row.user[0].username }}
              </div>
            </div>
            <div v-else-if="props.column.field == 'user.email'">
              <div v-if="props.row.user.length > 0">
                {{ props.row.user[0].email }}
              </div>
            </div>
            <div v-else-if="props.column.field == 'game.gameName'">
              <div>{{ props.row.details.gameName }}</div>
            </div>
            <div v-else-if="props.column.field == 'product.name'">
              <div>{{ props.row.product[0].name }}</div>
            </div>
            <div v-else-if="props.column.field == 'customer.name'">
              <div>{{ props.row.customer[0].name }}</div>
            </div>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="serverParams.perPage"
                  :options="pages"
                  class="mx-1"
                  @input="onPerPageChange"
                />
                <!-- <span class="text-nowrap"> of {{ props.total }} entries </span> -->
                <span class="text-nowrap">
                  of
                  {{
                    props.total > 1000 ? `${props.total / 1000}k` : props.total
                  }}
                  entries
                </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="serverParams.page"
                  :total-rows="props.total"
                  :per-page="serverParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="onPageChange"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <div v-if="dataItemsTotals.length > 0">
          <!-- totales -->
          <div class="text-center bg-gray-200 p-2">
            <h4>Totales</h4>
          </div>

          <vue-good-table
            :columns="columnsTotals"
            :rows="dataItemsTotals"
            :pagination-options="{ enabled: false }"
            :sort-options="{ enabled: false }"
          >
            <div slot="emptystate" class="text-center">
              No hay datos para mostrar
            </div>
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'currency'">
                <p>{{ props.row.currency }}</p>
              </div>
              <div v-if="props.column.field == 'bet'">
                <p>{{ props.row.bet | currency({ symbol: "" }) }}</p>
              </div>
              <div v-if="props.column.field == 'totalBet'">
                <p>{{ props.row.totalBet | currency({ symbol: "" }) }}</p>
              </div>
              <div v-if="props.column.field == 'profit'">
                <p>{{ props.row.profit | currency({ symbol: "" }) }}</p>
              </div>
              <div v-if="props.column.field == 'profitNet'">
                <p>{{ props.row.profitNet | currency({ symbol: "" }) }}</p>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import gamesStoreModule from "@/store/games";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDate } from "@core/utils/filter";
import moment from "moment";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { mapActions } from "vuex";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    BSpinner,
    ToastificationContent,
    StatisticCardHorizontal,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      exportDataLoading: false,
      timeOutSearchCustomer: null,
      loadingData: false,
      searchQuery: "",
      pages: [20, 40, 60, 100, 120, 200, 500, 1000],
      columnsTotals: [
        { label: "Moneda", field: "currency" },
        { label: "Apuesta", field: "bet" },
        { label: "Total Apuesta", field: "totalBet" },
        { label: "Ganancia", field: "profit" },
        { label: "Ganancia Neto", field: "profitNet" },
      ],
      dataItemsTotals: [],
      columns: [
        { label: "Usuario", field: "user.username" },
        { label: "Email", field: "user.email" },
        { label: "Juego", field: "game.gameName" },
        { label: "Producto", field: "product.name" },
        { label: "Cliente", field: "customer.name" },
        { label: "Tipo", field: "type" },
        { label: "TransactionId", field: "transactionID" },
        { label: "Round", field: "referenceId" },
        { label: "Moneda", field: "currency" },
        { label: "Monto", field: "amount" },
        { label: "Fecha", field: "createdAt" },
      ],
      rows: [],
      serverParams: {
        page: 1,
        perPage: 20,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      gamesOptions: [],
      currencyssOptions: [
        "ARS",
        "BRL",
        "CLP",
        "COP",
        "EUR",
        "MXN",
        "MYR",
        "PEN",
        "PYG",
        "USD",
        "UYU",
        "VES",
        "XAF",
      ],
      filterSelect: {
        dateTo: null,
        dateFrom: null,
        selectCustomer: "",
        selectProduct: null,
        selectCurrency: "",
        selectGames: [],

        q: "",
        WL: "",
      },
      timeoutGetTransactions: null,
      minDate: null,
      maxDate: null,
      wlOptions: [],
      loadingWlOptions: false,
      loadingGames: false,
      searchGamesTimeout: null,
    };
  },
  computed: {
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    userLoged() {
      return this.$store.getters["usersModule/userLoged"];
    },
    cardCabezera() {
      return [
        {
          statistic: 25,
          color: "success",
          icon: "PlayCircleIcon",
          title: "Total Spins",
        },
        {
          statistic: 25,
          color: "danger",
          icon: "ActivityIcon",
          title: "Total Sales",
        },
        {
          statistic: 25,
          color: "success",
          icon: "TrophyIcon",
          title: "Total Wins",
        },
        {
          statistic: 25,
          color: "danger",
          icon: "DollarSignIcon",
          title: "Total Profit",
        },
      ];
    },
    areAllGamesSelected() {
      return (
        this.gamesOptions.length > 0 &&
        this.filterSelect.selectGames.length === this.gamesOptions.length
      );
    },
  },
  // add filters
  filters: {
    formatDate,
  },
  watch: {
    "filterSelect.selectProduct": {
      handler(value) {
        this.filterSelect.selectGames = [];
        this.getGames();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("reports", ["downloadReportFile"]),

    downloadFile() {
      this.exportDataLoading = true;
      const VUE_APP_GATEWAY = process.env.VUE_APP_GATEWAY;
      const filter = {
        dateFrom: moment(this.filterSelect.dateFrom)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        dateAt: moment(this.filterSelect.dateTo)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        customerId: this.filterSelect.selectCustomer,
        currency: this.filterSelect.selectCurrency,
        product: this.filterSelect.selectProduct,
        q: this.filterSelect.q,
        WL: this.filterSelect.WL,
      };
      const finalUrl = VUE_APP_GATEWAY + "report/plays";
      const format = ".xlsx";
      const customerName = this.customerOptions.find(
        (customer) => customer._id == filter.customerId
      );
      const fileTitle = `plays-report-${
        filter.product
      }-${customerName?.name.toUpperCase()}-(${
        filter.dateFrom + " - " + filter.dateAt
      })${format}`;

      this.downloadReportFile({
        filter: filter,
        fileTitle: fileTitle,
        url: finalUrl,
      })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("labels.errorDownloadFile"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.message}`,
            },
          });
        })
        .finally(() => (this.exportDataLoading = false));
    },
    async searchCustomer(search, loading) {
      loading(true);
      try {
        clearTimeout(this.timeOutSearchCustomer);

        this.timeOutSearchCustomer = setTimeout(async () => {
          const res = await this.$store.dispatch(
            "customerModule/getCustomers",
            {
              q: search,
              paginate: {
                page: 1,
                perPage: 20,
                sortDesc: true,
              },
            }
          );
          this.customerOptions = res.data.customers;
          loading(false);
        }, 500);

        loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    async getTransactions() {
      try {
        this.loadingData = true;
        this.rows = [];
        const params = {
          ...this.filterSelect,
          ...this.serverParams,
          selectProduct: this.filterSelect.selectProduct?.productId,
        };

        const res = await this.$store.dispatch(
          "reports/getTransactionsPlain",
          params
        );
        this.rows = res.data.transactions || [];
        this.totalRows = res.data.countDocuments || 0;

        this.dataItemsTotals = (res.data.totals || []).map((item) => {
          return {
            currency: item._id,
            bet: item.spin,
            totalBet: item.debit,
            profit: item.credit,
            profitNet: item.profit,
          };
        });
      } catch (error) {
        console.log(error);
        // validar si viene un mensaje de error
        if (error.response.data.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t(error.response.data.message),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching transactions list",
              title: "Error al obtener las transacciones",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      } finally {
        this.loadingData = false;
      }
    },
    async getWlOptions() {
      try {
        this.loadingWlOptions = true;
        const customerSelected = this.customerOptions.find(
          (customer) => customer._id == this.filterSelect.selectCustomer
        );
        const res = await this.$store.dispatch("reports/getWlOptions", {
          customerId: customerSelected.customerId,
        });
        this.wlOptions = res.data;
        this.filterSelect.WL = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingWlOptions = false;
      }
    },
    fillZero(number, length) {
      var my_string = "" + number;
      while (my_string.length < length) {
        my_string = "0" + my_string;
      }

      return my_string;
    },
    onPageChange(page) {
      this.serverParams.page = page;
      this.getTransactions();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.perPage = limit;
      this.getTransactions();
    },
    inputSelect() {
      if (
        (!this.filterSelect.selectCustomer && this.typeUserLoged == "Root") ||
        !this.filterSelect.selectProduct
      )
        return;
      this.serverParams.page = 1;
      clearTimeout(this.timeoutGetTransactions);
      this.timeout = setTimeout(() => {
        this.getTransactions();
      }, 500);
    },
    selectCustomer(customer) {
      this.productsOptions = customer.products.map((product) => ({
        ...product,
        productId: product.productId,
        _id: product._id,
      }));

      this.filterSelect.selectProduct = null;
      if (customer.products && customer.products.length >= 1) {
        this.filterSelect.selectProduct = {
          productId: customer.products[0].productId,
          _id: customer.products[0]._id,
        };
      }

      this.getWlOptions();
    },
    async getCustomer() {
      const res = await this.$store.dispatch("customerModule/getCustomers", {
        paginate: {
          page: 1,
          perPage: 200,
          sortDesc: true,
        },
      });
      this.customerOptions = res.data.customers;
    },
    async getCurrencys() {
      const res = await this.$store.dispatch("reports/getCurrencys");
    },
    async getProducts() {
      const res = await this.$store.dispatch("reports/getProducts");
      this.productsOptions = res.data;
    },

    getGames(search) {
      if (!this.filterSelect.selectProduct) {
        return;
      }

      if (this.searchGamesTimeout) {
        clearTimeout(this.searchGamesTimeout);
      }

      try {
        this.searchGamesTimeout = setTimeout(async () => {
          this.loadingGames = true;

          const res = await this.$store.dispatch("gamesModule/getGames", {
            producSelectId: this.filterSelect.selectProduct._id,
            params: {
              perPage: 30,
              q: search,
            },
          });

          const selectedGames = this.gamesOptions.filter((game) =>
            this.filterSelect.selectGames.includes(game.gameId)
          );

          const newGames = res.data.games.filter(
            (game) =>
              !selectedGames.some((selected) => selected.gameId === game.gameId)
          );

          this.gamesOptions = [...selectedGames, ...newGames];
          this.loadingGames = false;
        }, 1000);
      } catch (error) {
        console.error("Error al buscar juegos:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error cargando juegos",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingGames = false;
      }
    },

    inputDateFrom(date) {
      // minDate restarle 30 dias a la fecha actual
      // maxDate sumarle 30 dias a la fecha actual
      // this.minDate = moment(date).subtract(29, 'days').format('YYYY-MM-DD 00:00')
      // this.maxDate = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59')
      // this.filterSelect.dateTo = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59')
    },
    selectAllGames() {
      this.filterSelect.selectGames = this.gamesOptions.map(
        (game) => game.gameId
      );
    },
    unselectAllGames() {
      this.filterSelect.selectGames = [];
    },
  },
  created() {
    this.filterSelect.dateFrom = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD 00:00");
    this.filterSelect.dateTo = moment().format("YYYY-MM-DD 23:59");
    this.minDate = moment().subtract(29, "days").format("YYYY-MM-DD 00:00");
    this.maxDate = moment().format("YYYY-MM-DD 23:59");
    // Menu Collapsed false
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);

    if (!store.hasModule("gamesModule"))
      store.registerModule("gamesModule", gamesStoreModule);

    this.getCustomer();
    // this.getCurrencys();
    this.getProducts();
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    if (store.hasModule("gamesModule")) store.unregisterModule("gamesModule");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
